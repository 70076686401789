import React, { useState, useEffect } from 'react';
import styles from './App.module.scss';
import { useTranslation } from 'react-i18next';
import { Routes, Route,Link,useLocation } from "react-router-dom";
import About from './views/about';
import Solutions from './views/solutions';
import Partners from './views/partners';
import Products from './views/products'; 


const App = () => {
  const [isShowpnlnav, setIsShowpnlnav] = useState(false);
  const { t, i18n } = useTranslation();
  const location = useLocation(); // 获取当前路由地址
  const [isShowLangOptions, setIsShowLangOptions] = useState(false);
  const [langOptions] = useState({
    en: 'English',
    zh: '简体中文',
  });
  useEffect(() => {
    document.title = i18n.language == 'en' ? 'Yolo' : 'Yolo';
  }, []);

  useEffect(() => {
    setIsShowpnlnav(false);
  },[location.pathname]);

  function isMobileDevice(screenWidthVal = 768) {
    const userAgent = navigator.userAgent;
    const screenWidth =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    const isMobile = /Mobile/i.test(userAgent) || screenWidth < screenWidthVal;
    return isMobile;
  }

  return (
    <div className={`${styles.yolo_wrapper} ${styles[i18n.language]}`} onClick={()=>{
      setIsShowpnlnav(false);
      setIsShowLangOptions(false);
    }}>
      <div className={styles.header}>
        <Link to="/"><div className={styles.logo}></div></Link>
        <div className={styles.btn_nav} onClick={(event)=>{
          setIsShowpnlnav(!isShowpnlnav);
          event.stopPropagation();
        }}>
          <div className={styles.name}>
            {
              location.pathname == "/" ? t("index.discover") : t(`index.${location.pathname.replace('/','')}`)
            }
          </div>
          <div className={`${styles.icon} ${isShowpnlnav?styles.open:''}`}></div>
        </div>
        {
          isShowpnlnav && <ul className={styles.pnl_nav}>
            <li className={`${styles.nav_item} ${location.pathname=='/about'?styles.active:''}`}><Link to="/about">{t("index.about")}</Link></li>
            <li className={`${styles.nav_item} ${location.pathname=='/partners'?styles.active:''}`}><Link to="/partners">{t("index.partners")}</Link></li>
            <li className={`${styles.nav_item} ${location.pathname=='/solutions'?styles.active:''}`}><Link to="/solutions">{t("index.solutions")}</Link></li>
            <li className={`${styles.nav_item} ${location.pathname=='/products'?styles.active:''}`}><Link to="/products">{t("index.products")}</Link></li>
          </ul>
        }
        
        <ul className={styles.nav}>
          <li className={`${styles.nav_item} ${location.pathname=='/about'?styles.active:''}`}><Link to="/about">{t("index.about")}</Link></li>
          <li className={`${styles.nav_item} ${location.pathname=='/partners'?styles.active:''}`}><Link to="/partners">{t("index.partners")}</Link></li>
          <li className={`${styles.nav_item} ${location.pathname=='/solutions'?styles.active:''}`}><Link to="/solutions">{t("index.solutions")}</Link></li>
          <li className={`${styles.nav_item} ${location.pathname=='/products'?styles.active:''}`}><Link to="/products">{t("index.products")}</Link></li>
        </ul>
        <div onClick={(event)=>{
          setIsShowLangOptions(!isShowLangOptions);
          event.stopPropagation();
        }} className={styles.earth}></div>
        {isShowLangOptions && (
              <div className={styles.lang_options}>
                {Object.keys(langOptions).map((key) => (
                  <div
                    className={`${styles.item} ${
                      i18n.language == key ? styles.active : ''
                    }`}
                    onClick={() => {
                      localStorage.setItem('NB_LANG', key);
                      i18n.changeLanguage(key);
                      setIsShowLangOptions(false);
                      document.title =
                        i18n.language == 'en' ? 'yolo' : 'yolo';
                    }}
                  >
                    {langOptions[key]}
                  </div>
                ))}
              </div>
            )}
      </div>


      <Routes>
        <Route path="/" element={
          <div className={styles.wrapper}>
            <img src={t("index.pic_biaoti")} className={styles.pic_title} alt="" />
            <img src={t("index.pic_biaoti_mobile")} className={styles.pic_title_mobile} alt="" />
            <img src={require('./images/index/shoutu.png')} className={styles.pic_home} alt="" />
            <img src={require('./images/index/tu1.png')} className={styles.pic_home_mobile} alt="" />
            
          </div>
          } 
        />
        <Route path="/about" element={<About></About>} />
        <Route path="/partners" element={<Partners></Partners>} />
        <Route path="/solutions" element={<Solutions></Solutions>} />
        <Route path="/products" element={<Products></Products>} />
      </Routes>

    </div>
  );
};



export default App;
