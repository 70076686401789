

import React, { useState, useEffect } from 'react';
import styles from '../App.module.scss';
import { useTranslation } from 'react-i18next';


const About = () => {
    const { t, i18n } = useTranslation();

    return (
      <div className={styles.partners_wrapper}>
         <div className={styles.title}>{t("partners.partnership")}</div>
         <div className={styles.line}></div>
         <div className={styles.content}>
            <ul className={styles.list}>
              <li>
                <img src={require('../images/partners/1.png')} alt="" />
              </li>
              <li>
                <img src={require('../images/partners/2.png')} alt="" />
              </li>
            </ul>
            <div className={styles.subTitle}>{t("partners.desc")}</div>
        </div>
      </div>
    );
  };

export default About;