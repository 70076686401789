
import React, { useState, useEffect } from 'react';
import styles from '../App.module.scss';
import { useTranslation } from 'react-i18next';


const About = () => {
    const { t, i18n } = useTranslation();

    useEffect(() => {
        
      console.log('about');
    }, []);
    return (
      <div className={styles.about_wrapper}>
        <div className={styles.title}>{t("about.about_us")}</div>
        <div className={styles.line}></div>

        <div className={styles.content}>
            <div className={styles.subTitle}>{t("about.our_vision")}</div>
            <div className={styles.vision}>{t("about.our_vision_desc")}</div>
            <div className={styles.subTitle}>{t("about.our_mission")}</div>
            <ol className={styles.text} dangerouslySetInnerHTML={{ __html: t('about.our_mission_desc') }}></ol>
        </div>
      </div>
    );
  };

export default About;