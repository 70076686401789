
import React, { useState, useEffect } from 'react';
import styles from '../App.module.scss';
import { useTranslation } from 'react-i18next';


function isMobileDevice(screenWidthVal = 768) {
  const userAgent = navigator.userAgent;
  const screenWidth =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;
  const isMobile = /Mobile/i.test(userAgent) || screenWidth < screenWidthVal;
  return isMobile;
}

const Solutions = () => {
    const { t, i18n } = useTranslation();

    return (
      <div className={styles.solutions_wrapper}>
      <div className={styles.title}>{t("solutions.solutions")}</div>
      <div className={styles.line}></div>
      <div className={styles.content}>
         <ul className={styles.list}>
          {
            (isMobileDevice()?[1,2,3,4]:[1,3,2,4]).map((key)=>{
              return    <li className={styles[`item_${key}`]}>
              <div className={styles.index}>{key}</div>
              <div className={styles.item_title}>{t(`solutions.item_${key}.title`)}</div>
              <div className={styles.item_text} dangerouslySetInnerHTML={{ __html: t(`solutions.item_${key}.desc`) }}>
              </div>
            </li>
            })
          }
       

        
         </ul>
     </div>
   </div>
    );
  };

export default Solutions;