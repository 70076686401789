
import React, { useState, useEffect } from 'react';
import styles from '../App.module.scss';
import { useTranslation } from 'react-i18next';



const Products = () => {
  const { t, i18n } = useTranslation();

    return (
      <div className={styles.products_wrapper}>
        <div className={styles.title}>{t("index.products")}</div>
        <div className={styles.line}></div>
        <div className={styles.content}>
          <ul className={styles.list}>
              {
                [1,2,3,4,5,6,7,8].map((item,index)=>{
                  return (
                    <li key={index}>
                      <img src={require(`../images/products/${item}.png`)} alt="" />
                      <div className={styles.title}>{t(`products.item_${item}`)}</div>
                    </li>
                  )
                })
              }
          </ul>
        </div>
      </div>
    );
  };

export default Products;