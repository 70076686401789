const messages = {
      en: {
            translation: {
                  "index": {
                        "discover": "Discover",
                        "about": "About",
                        "partners": "Partners",
                        "solutions": "Solutions",
                        "products": "Products",
                        "pic_biaoti": require('../images/index/biaoti.png'),
                        "pic_biaoti_mobile":require('../images/index/biaoti_mobile_1.png'),
                  },
                  "about": {
                        "about_us": "ABOUT US",
                        "our_vision": "Our Vision",
                        "our_vision_desc": "To revolutionize the mobile gaming industry by harnessing the power of artificial intelligence, creating immersive experiences that captivate players worldwide and drive unprecedented growth for game developers.",
                        "our_mission": "Our Mission",
                        "our_mission_desc": `<li>Empower game developers to reach their full potential by leveraging cutting-edge AI technologies for user acquisition and revenue optimization.</li>
            <li>Deliver innovative, AI-driven mobile games that provide unparalleled entertainment and value to players across the globe.</li>
            <li>Continuously push the boundaries of AI integration in mobile gaming, setting new industry standards for engagement, personalization, and monetization.</li>
            <li>Foster strong partnerships with game developers like 9palace games, supporting their creative vision while maximizing their market success through our AI-powered publishing platform.</li>
            <li>Maintain our position as a top-tier AI-powered Mobile Game Publisher by consistently delivering high-performing games and exceeding revenue targets.</li>
            <li>Invest in research and development to create abundant AI features that enhance gameplay, automate marketing processes, and drive user growth.</li>
            <li>Cultivate a culture of innovation and excellence, attracting top talent in both AI and gaming to fuel our continued expansion and success in the mobile gaming market.</li>`
                  },
                  "partners": {
                        "partnership": "Partnership",
                        "desc":"Welcome collaboration from various media channels, technology providers, and game developers."
                  },
                  "solutions":{
                        "solutions":"Solutions",
                        "item_1":{
                              "title":"Revolutionizing Game Art with AIGC",
                              "desc":`-Utilize AI-generated content (AIGC) to rapidly create high-quality game assets<br/>
                              -Optimize character designs, scenes, and UI elements to enhance visual appeal<br/>
                              -Combine Stable Diffusion with pose control for accurate and stylistically consistent art<br/>
                              - Adapt character illustrations to suit the preferences of targeted global markets`
                        },
                        "item_2":{
                              "title": "Crafting Immersive Experiences with AI-Driven NPCs",
                              "desc": `-Develop intelligent NPCs that offer dynamic, branching interactions <br/>
                              - Enable NPCs to respond to player choices, creating unique story paths<br/>
                              - Leverage AI to generate personalized dialogue and quests for each player<br/>
                              - Create living, reactive game worlds that adapt to player actions`
                        },
                        "item_3":{
                              "title": "Enhancing Gameplay with Adaptive AI",
                              "desc": `- Employ AI algorithms to balance game difficulty based on player skill<br/>
                              - Create challenging yet rewarding experiences that keep players engaged<br/>
                              - Develop intelligent bots that mimic human behavior for seamless multiplayer<br/>
                              - Optimize matchmaking and team composition to ensure fair, enjoyable gameplay<br/>
                              - Continuously adapt game balance and pacing based on player feedback and data`
                        },
                        "item_4":{
                              "title":"Pioneering the Future of Interactive Storytelling",
                              "desc":`- Harness AI to generate dynamic, player-driven narratives<br/>
                              - Create branching storylines that respond to player choices and actions<br/>
                              - Develop AI-powered dialogue systems for natural, context-aware conversations<br/>
                              - Enable players to shape the game world and their character's journey`
                        }
                  },
                  "products":{
                        "item_1":"Eastern Saga",
                        "item_2":"Blossoms",
                        "item_3":"Star havoc",
                        "item_4":"Eastern Saga 2",
                        "item_5":"Duoduo Play",
                        "item_6":"MIR",
                        "item_7":"Sword Song",
                        "item_8":"Clone: Crazy Alpha"
                  }
            }
      },
      zh: {
            translation: {
                  "index": {
                        "discover": "探索",
                        "about": "关于我们",
                        "partners": "合作伙伴",
                        "solutions": "解决方案",
                        "products": "产品",
                        "pic_biaoti": require('../images/index/biaoti-2.png'),
                        "pic_biaoti_mobile":require('../images/index/biaoti_mobile_2.png'),
                  },
                  "about": {
                        "about_us": "关于我们",
                        "our_vision": "我们的愿景",
                        "our_vision_desc": "通过利用人工智能的力量，彻底改变移动游戏行业，创造沉浸式体验，吸引全球玩家，并为游戏开发者带来前所未有的增长。",
                        "our_mission": "我们的使命",
                        "our_mission_desc": `<li>通过利用尖端的人工智能技术，帮助游戏开发者实现用户获取和收入优化，充分发挥其潜力。</li>
            <li>提供创新的、由人工智能驱动的移动游戏，为全球玩家带来无与伦比的娱乐和价值。</li>
            <li>不断突破人工智能在移动游戏中的集成界限，为参与度、个性化和货币化设定新的行业标准。</li>
            <li>与像九宫格游戏这样的游戏开发者建立强有力的合作关系，支持他们的创意愿景，同时通过我们的AI驱动发布平台最大化其市场成功。</li>
            <li>通过持续发布高性能游戏和超额完成收入目标，保持我们作为顶级AI驱动移动游戏发行商的地位。</li>
            <li>投资于研究和开发，创造丰富的人工智能功能，增强游戏体验，自动化营销流程，并推动用户增长。</li>
            <li>培养创新和卓越的文化，吸引人工智能和游戏领域的顶尖人才，为我们在移动游戏市场的持续扩展和成功提供动力。</li>`

                  },
                  "partners": {
                        "partnership": "合作伙伴",
                        "desc":"欢迎各种媒体渠道，技术提供方、游戏开发者联络合作"
                  },
                  "solutions":{
                        "solutions":"解决方案",
                        "item_1":{
                              "title":"通过AIGC革新游戏艺术",
                              "desc":`利用人工智能生成内容（AIGC）快速创建高质量的游戏资产<br/>
                              优化角色设计、场景和UI元素，以增强视觉吸引力<br/>
                              将稳定扩散技术与姿态控制结合，确保艺术作品的准确性和风格一致性<br/>
                              调整角色插图以适应目标全球市场的偏好`
                        },
                        "item_2":{
                              "title":"通过AI驱动的NPC打造沉浸式体验",
                              "desc":`开发智能NPC，提供动态的分支互动<br/>
                              使NPC能够响应玩家选择，创造独特的故事路径<br/>
                              利用AI为每位玩家生成个性化的对话和任务<br/>
                              创建生动、反应灵活的游戏世界，根据玩家行为进行调整`
                        },
                        "item_3":{
                              "title":"利用自适应人工智能提升游戏玩法",
                              "desc":`使用AI算法根据玩家技能平衡游戏难度<br/>
                              创造具有挑战性但又令人满足的游戏体验，保持玩家的参与度<br/>
                              开发智能机器人，模仿人类行为，实现无缝的多人游戏体验<br/>
                              优化匹配和团队组成，确保公平、愉快的游戏体验<br/>
                              根据玩家反馈和数据持续调整游戏平衡和节奏`
                        },
                        "item_4":{
                              "title":"开创互动叙事的未来",
                              "desc":`利用AI生成动态的、由玩家驱动的叙事<br/>
                              创建根据玩家选择和行动响应的分支故事线<br/>
                              开发AI驱动的对话系统，实现自然、上下文感知的对话<br/>
                              让玩家塑造游戏世界和角色的旅程`
                        }
                  },
                  "products":{
                        "item_1":"幻想名将录",
                        "item_2":"繁华三千集",
                        "item_3":"远星",
                        "item_4":"群英风华录",
                        "item_5":"多多玩",
                        "item_6":"传奇",
                        "item_7":"一念剑歌",
                        "item_8":"克隆：疯狂的阿尔法"
                  }
            }
      }
}

export default messages;
